import React from "react";

import "./style.scss";

export default function Home(props) {
  return (
    <div className="not-found-page">
      <div className="overlay"></div>

      <div className="logo">
        <img
          src={require("../../assets/logos/roberto-e-figueredo-white-2.png")}
          alt="Escritório de Advocacia DF"
        />
      </div>

      <div className="information">
        <div>
          <h1 className="title">404</h1>
        </div>

        <div>
          <p className="message">
            Desculpe, não foi possível encontrar essa página.
          </p>
        </div>

        <div>
          <a href="/" className="link">
            Comece na página inicial
          </a>
        </div>
      </div>
    </div>
  );
}
