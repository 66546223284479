import React from 'react';
import SlickSlider from '../SlickSlider';

import Avatar from '../Avatar';
import reviewsJSON from '../../mocks/reviews.json'

import './style.scss'
import Rating from '../Rating';


export default function Reviews() {
  
  return <div className='review__container'>
    <SlickSlider>
      {reviewsJSON.map((review, index) => (
        <div className='card__container' key={index}>
          <div className="card__head">
            <Avatar name={review.name} image={review.image}/>
            <span className='user__name'>{review.name}</span>
          </div>
          <div className="card__content">“{review.review}”</div>
          <div className="card__rating">
            <div>
              <Rating value={review.score}/>
            </div>
            <div className='google__review'>Avaliação do Google</div>
          </div>
        </div>
      ))}
    </SlickSlider>
  </div>
}
