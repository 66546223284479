import React from 'react';

import './style.scss'

export default function Rating ({ value }) {
  return <div className='rating__container'>
    {[1, 2, 3, 4, 5].map((item, index) => (
      <span className={`material-icons rating ${value >= item ? 'rating--active' : ''}`} key={index}>star_rate</span>
    ))}
  </div>
}
