import React from 'react';

import './style.scss';
import '../../assets/font/flaticon.css';

export default function Card(props) {

    const item = props.item

    return <div className="item-card">
        <div className="item">
            <div className="icon-item mt-4" style={{backgroundColor: item.backgroundColor}}>
                <i className={`flaticon ${item.icon ? item.icon : ''}`} style={{color: item.color}}></i>
            </div>
            <div className="title-item mt-3">
                <h4>{item.name}</h4>
                <p> { item.description ? item.description : 'Lorem ipsum dolor sit, amet consectetur adipisicing elit!' }</p>
            </div>
        </div>
    </div> 
}
