import React from 'react';

import './style.scss';

export default function Lawyer(props) {

    const lawyer = props.lawyer

    return   <div className="">
        <div className="single-lawyer text-center mt-30">
            <div className="lawyer-image" style={{backgroundImage: `url(${require(`../../assets/${lawyer.image}`)})`}}></div>
            <div className="lawyer-content">
                <h4 className="lawyer-name"><a href="/" aria-label="Conheça nossos advogados">{lawyer.name}</a></h4>
                <span className="sub-title">{lawyer.description }</span>
                <ul className="social mt-25">
                    <li>
                        <i className="bi bi-postcard"></i>
                        <span> {lawyer.oabNumber} OAB/DF</span>
                    </li>
                    <li>
                        <i className="bi bi-phone"></i>
                        <span>{lawyer.phone}</span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
}