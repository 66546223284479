import React, { useState, useEffect, useRef } from 'react';

import './index.scss';

export default function Header(props) {

    const [backgroundNav, setBackgroundNav] = useState(false)

    const wrapperRef = useRef(null)
    useOutsideAlerter(wrapperRef);

    useEffect(() => {
        if (props.scrollY > 580) {
            setBackgroundNav(false)
        }
    }, [props.scrollY])

    const handleClickMenu = () => {
        if (props.scrollY < 580) {
            setBackgroundNav(!backgroundNav)
        }

        console.log('props.scrollY', props.scrollY);
    }

    const handleCloseMenu = () => {
        setBackgroundNav(false)
    }
    function useOutsideAlerter(ref) {
        useEffect(() => {
          function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
              setBackgroundNav(false)
            //   $('.navbar-collapse').collapse('hide');
            }
          }
          // Bind the event listener
          document.addEventListener("mousedown", handleClickOutside);
          return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
          };
        }, [ref]);
    }

    return  <header className="custom-menu">
        <nav className={`navbar navbar-expand-lg fixed-top ${props.scrollY > 580 ? 'header-custom' : ''} ${backgroundNav ? 'navBgDark' : ''}`} ref={wrapperRef}>
            <div className="container-fluid">
                <a className="navbar-brand" href="/">
                    {props.scrollY < 580 ? 
                        <div className="logo">
                            <img src={require('../../assets/logos/roberto-e-figueredo-white-2.png')} alt="Escritório de Advocacia DF" width="160" height="40"/>
                        </div> :
                        <div className="logo-bg-white">
                            <img src={require('../../assets/logos/roberto-e-figueredo-gray-2.png')} alt="Escritório de Advocacia DF" width="160" height="40"/>
                       </div>
                    }
                </a>

                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" area-aria-label='Menu' aria-label="Menu" onClick={handleClickMenu}>
                    <i className="bi bi-list" style={{color: props.scrollY > 580 ? '#233043': ''}}></i>
                </button>

                <div className={`collapse navbar-collapse ${backgroundNav ? 'show' : 'hide'}`} id="navbarCollapse">
                    <ul className={`navbar-nav ms-auto mb-2 mb-md-0 ${props.scrollY > 580 ? 'custom-nav-link' : ''}`}>
                        <li className="nav-item" onClick={handleCloseMenu}>
                            <a className="nav-link" href="/">Início</a>
                        </li>
                        <li className="nav-item" onClick={handleCloseMenu}>
                            <a className="nav-link" href="#about">O escritório</a>
                        </li>
                        <li className="nav-item" onClick={handleCloseMenu}>
                            <a className="nav-link" href="#acting">Áreas de atuação</a>
                        </li>
                        <li className="nav-item" onClick={handleCloseMenu}>
                            <a className="nav-link" href="#lawyers">Nossa equipe</a>
                        </li>
                        <li className="nav-item" onClick={handleCloseMenu}>
                            <a className="nav-link" href="#news">Notícias</a>
                        </li>
                        <li className="nav-item"onClick={handleCloseMenu}>
                            <a className="nav-link" href="#contact">Agende uma consultoria</a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    </header>
}