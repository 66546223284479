import React from 'react';

import './style.scss';

export default function Avatar({ name, image }) {

  const getInitials = name => {
    const names = name.split(' ')

    let initials = names[0].substring(0, 1).toUpperCase();
    
    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }

    return initials;
  }

  return <div className='avatar__container'>
    {!image && <div className='avatar__initials'>
      <span>{getInitials(name)}</span>
    </div>}
    {image && <div className='avatar__image'>
      <img src={require(`../../assets/reviews/${image}`)} alt={name}/>
    </div>}
  </div>
}

