import React from 'react';

import './style.scss';

export default function News(props) {
    const news = props.news

    return <div className="news-card">
        <div className="image-cap" style={{backgroundImage:  `url(${require(`../../assets/${news.image}`)})`}}></div>
        <div className="news-info">
            <p className="category-of-law">{news.category}</p>
            <p className="title-news" title={news.titleAlt}>
                {news.title}
            </p>
            <p className="paragraph-news">{news.description}</p>
            <div className="news-card-footer">
                <div className="news-author">
                    <div className="image-author">
                        <img src={require(`../../assets/${news.authorsImage}`)} alt={news.author} />
                    </div>
                    <small>{news.author}</small>
                </div>
                <div className="news-link">
                    <a href={news.link} target="blank" aria-label="Leia mais no site">{news.linkTitle}</a>
                </div>
            </div>
        </div>
    </div>
}