import React, { useEffect, useState } from 'react';

import api from '../../api';

import './style.scss';

import PraticesJson from '../../mocks/pratices.json';
import LawyersJson from '../../mocks/lawyers.json';
import NewsJson from '../../mocks/news.json';

import Header from '../../components/Header';
import Carousel from '../../components/Carousel';
import Card from '../../components/Card';
import Lawyer from '../../components/Lawyer';
import News from '../../components/News';
import Toast from '../../components/Toast';
import Reviews from '../../components/Reviews';

export default function Home(props) {
    const date = new Date()
    const fullYear = date.getFullYear()

    const [scrollPosition, setScrollPosition] = useState(0);
    
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [scheduling, setScheduling] = useState('')
    const [action, setAction] = useState('Direito Penal')

    const [disabled, setDisabled] = useState(true)

    const [visibleToast, setVisibleToast] = useState(false)


    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const changeField = (event, field) => {
        applyFormFields(event, field)

        setTimeout(() => {
            if (name && email && phone && scheduling && action) {
                setDisabled(false)
            }
        }, 500)
    }

    const applyFormFields = (event, field) => {
        switch (field) {
            case 'name':
                setName(event.target.value)
                break;
            case 'email':
                setEmail(event.target.value)
                break;
            case 'phone':
                setPhone(event.target.value)
                break;
            case 'scheduling':
                setScheduling(event.target.value)
                break;
            case 'action':
                setAction(event.target.value)
                break;
        
            default:
                break;
        }
    }


    const handleForm = e => {
        e.preventDefault()
        setDisabled(true);

        const data = {name, email, phone, scheduling: new Date(scheduling).toLocaleString(), action}

        api.post('mail/send-mail', data).then(() => {
            setName('')
            setEmail('')
            setPhone('')
            setScheduling('')
            setAction('Direito Penal')

            setVisibleToast(true)
            
            setTimeout(() => {
                setVisibleToast(false)
            }, 6000)
        })


    }

    return <div className='home-container'>
        <Header scrollY={scrollPosition} />

        <main>
            <div className='home__carousel'>
                <Carousel />
            </div>

            {/* About Section  */}
            <section id="about" className="py-4">
                <div className="container-section">
                    <div className="row default-spacing">
                        <div className="col-md-6">
                            <img src={require('../../assets/office/serv_localizacao.webp')}
                                alt="Localização Roberto e Figueredo Advocacia - Escritório de advocacia" className="img-fluid" width="500" height="375"/>
                        </div>
                        <div className="col-md-6 py-5">
                            <div className="spotlight pb-2">Escritório</div>
                            <div className="about-the-office">
                                <h2>Nosso Escritório</h2>
                                <p className="mt-5">O escritório <strong>Roberto & Figueredo Advocacia</strong> presta serviços para diversas áreas do direito, com foco em resolver seus problemas.
                                    Temos uma equipe de profissionais qualificados e aptos para te atender desde os casos
                                    mais simples aos mais complexos.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Areas of expertise */}
            <section id="acting" className="py-4">
                <div className="container-section py-5">
                    <div className="text-center mb-5">
                        <div className="spotlight pb-2">Atuação</div>
                        <h3>Áreas Que Atuamos</h3>
                    </div>
                    <div className="grid-columns">
                        {
                            PraticesJson.map((item, index) => <Card item={item} key={index}/>)
                        }
                    </div>
                </div>
            </section>

            {/* Lawyers  */}
            <section id="lawyers" className="py-4">
                <div className="container-section py-5">
                    <div className="text-center mb-5">
                        <div className="spotlight pb-2">Equipe</div>
                        <h3>Conheça Nossos Advogados</h3>
                    </div>
                    <div className="grid-columns-lawyer">
                        {
                            LawyersJson.map((lawyer, index) => <Lawyer lawyer={lawyer} key={index}/>)
                        }
                    </div>
                </div>
            </section>

            {/* Reviews */}
            <section id='reviews' className='py-4'>
             <div className='text-center mb-5'>
              <div className="spotlight pb-2">Depoimentos</div>
              <h3>O Que Dizem Nossos Clientes</h3>
             </div>

              <Reviews />
            </section>

            {/* News  */}
            <section id="news" className="py-4">
                <div className="container-section py-5">
                    <div className="text-center mb-5">
                        <div className="spotlight pb-2">Notícias</div>
                        <h3>Veja O Que Anda Acontecendo</h3>
                    </div>
                    <div className="grid-columns-news">
                        {
                            NewsJson.map((news, index) => <News news={news} key={index}/>)
                        }
                    </div>
                </div>
            </section>

            {/* Contact  */}
            <section id="contact" className="py-4">
                <div className="container-section py-5">
                    <div className="text-center mb-5">
                        <div className="spotlight pb-2">Contato</div>
                        <h3>Adoraríamos Ajudar Você</h3>
                    </div>

                    <div className="grid-columns-badges">
                        <div className="badges badge-map pt-3">
                            <div className="badge-icon">
                                <i className="bi bi-map"></i>
                            </div>
                            <div className="badge-content">
                                <p><strong>Endereço</strong></p>
                                <p className="badge-text">Edifício Taguatinga Trade Center, Sala 207, Taguatinga, Brasília</p>
                            </div>
                        </div>
                        <div className="badges badge-contact pt-3">
                            <div className="badge-icon">
                                <i className="bi bi-telephone"></i>
                            </div>
                            <div className="badge-content">
                                <p><strong>Telefones</strong></p>
                                <div className="badge-text"><i className="bi bi-plus"></i> 61 9 8352 3196 - Drº Antônio</div>
                                <div className="badge-text"><i className="bi bi-plus"></i> 61 9 9377 4142 - Draª Francimeire</div>
                                <div className="badge-text"><i className="bi bi-plus"></i> 61 9 9275 8959 - Draª Fernanda</div>
                            </div>
                        </div>
                        <div className="badges badge-mail pt-3">
                            <div className="badge-icon">
                                <i className="bi bi-envelope"></i>
                            </div>
                            <div className="badge-content">
                                <p><strong>E-mail</strong></p>
                                <p className="badge-text">rfadvocaciacj@gmail.com</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        
            <section className="mt-5">
                <div className="grid-columns-contact">
                    <div className="contact-info ps-5 py-5">
                        <div className="mb-5">
                            <h4>
                                Novo caso? <br />Agende uma consultoria
                            </h4>
                            <p className="w-60 mt-5">Agende um horário com um de nossos especialistas e tire suas dúvidas.</p>
                            <p>Segunda-feira - Sexta-feira</p>
                            <p>09:00 - 18:00</p>
                        </div>

                        <div className="form">
                            <form id="contactForm">
                                <div className="form-group mb-4">
                                    <input type="text" className="form-control" name="name" id="name" value={name} placeholder="Nome" onChange={e => changeField(e, 'name')} aria-label="Nome"/>
                                </div>
                                <div className="form-group mb-4">
                                    <input type="email" className="form-control" name="email" id="email" value={email} placeholder="E-mail" onChange={e => changeField(e, 'email')} aria-label="Email"/>
                                </div>
                                <div className="form-group mb-4">
                                    <input type="text" className="form-control" name="phone" id="phone" value={phone} placeholder="Telefone" onChange={e => changeField(e, 'phone')} aria-label="Telefone"/>
                                </div>
                                <div className="form-group mb-4">
                                    <label htmlFor="">Data do agendamento</label>
                                    <input type="datetime-local" className="form-control" name="scheduling" id="scheduling" value={scheduling} onChange={e => changeField(e, 'scheduling')} aria-label="Data de agendamento"/>
                                </div>
                                <div className="mb-4">
                                    <select className="form-select" name="action" id="action" value={action} onChange={e => changeField(e, 'action')}>
                                        <option>Área do seu caso</option>
                                        <option value="Direito Penal">Direito Penal</option>
                                        <option value="Direito Civil">Direito Civil</option>
                                        <option value="Direito de Família, Órfãos e Sucessões">Direito de Família, Órfãos e
                                            Sucessões</option>
                                        <option value="Direito do Consumidor">Direito do Consumidor</option>
                                        <option value="Direito Trabalhista">Direito Trabalhista</option>
                                        <option value="Direito Previdenciário">Direito Previdenciário</option>
                                        <option value="Direito Administrativo">Direito Administrativo</option>
                                        <option value="Juizado Especial Cível">Juizado Especial Cível</option>
                                    </select>
                                </div>
                                <div className="form-group form-submit">
                                    <span className="btn btn-send" onClick={handleForm} style={{pointerEvents: disabled ? 'none' : ''}}>Solicitar agendamento</span>
                                </div>
                                <div id="send-form" className="text-success col-6 pt-2"></div>
                            </form>
                        </div>
                    </div>
                    <div className="contact-form d-none d-lg-block d-xl-block d-xxl-block">
                        <img src={require('../../assets/banner/lawsuit-image-03.webp')} alt="Roberto e Figueredo Advocacia - Escritório de advocacia" className="img-fluid" />
                    </div>
                </div>
            </section>

            {/* Footer */}
            <footer className="footer">
                <div className="container-fluid footer-info px-5">
                    <div className="row py-3 d-flex align-items-center">
                        <div className="col-md-6">
                            <p className=""><strong>Roberto & Figueredo Advocacia</strong> &copy; <span>{fullYear}</span>
                                - Todos os direitos
                                reservados</p>
                        </div>
                        <div className="col-md-6 ps-0">
                            <ul className="sociais ps-0 d-flex align-items-center">
                                <li>
                                    <span>Siga-nos nas redes sociais</span>
                                </li>
                                <li title="Facebook">
                                    <a href="https://pt-br.facebook.com/RFAdvocaciaeConsultoriaJuridica/" aria-label="Acesse nosso Instagram" target="blank">
                                        <i className="bi bi-facebook"></i>
                                    </a>
                                </li>
                                <li title="Instagram">
                                    <a href="https://www.instagram.com/robertoefigueredo_adv/" aria-label="Acesso nosso Facebook" target="blank">
                                        <i className="bi bi-instagram"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>

            { scrollPosition > 60 && <div className='whatsapp__action'>
                <a href="https://api.whatsapp.com/send?phone=5561992145641&text=" target="_blank" rel="noreferrer"><i className='bi bi-whatsapp'></i></a>
            </div> }

            <Toast message="Seu agendamento foi enviado com sucesso!" visible={visibleToast}/>
        </main>
    </div>
}