import React from 'react';

import './style.scss';

export default function Carousel(props) {
    return  <div id="myCarousel" className="carousel slide" data-bs-ride="carousel">
        <div className="overlay-carousel"></div>

        <div className="carousel-inner">
            <div className="carousel-item active">
                <img src={require('../../assets/banner/lawsuit-image-03.webp')} alt="Roberto e Figueredo Advocacia - Escritório de Advocacia e Consultoria Jurídica" />

                <div className="container">
                    <div className="carousel-caption">
                        <div className="caption-info text-start">
                            <h1 className="mb-5 caption-title">Escritório de Advocacia e Consultoria Jurídica</h1>
                            <p>Estamos aqui para te fornecer um atendimento de qualidade. Conte com uma equipe
                                especializada de Advogados para cuidar do seu caso.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="carousel-item">
                <img src={require('../../assets/banner/lawsuit-image-02.webp')} alt="Roberto e Figueredo Advocacia - Serviços jurídicos de alta qualidade" />
                <div className="container">
                    <div className="carousel-caption">
                        <div className="caption-info text-end">
                            <h2 className="mb-5 caption-title">Serviços jurídicos de alta qualidade</h2>
                            <p className="">
                                <strong>Roberto & Figueredo Advocacia</strong>, é um escritório de advocacia que
                                atua em diversas
                                áreas do direito. Se você ou alguém que você ama necessita de assistência
                                profissional - estamos aqui para ajudá-lo a tomar as melhores decisões legais.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="carousel-controls">
            <div className="control-prev" data-bs-target="#myCarousel" data-bs-slide="prev">
                <i className="bi bi-chevron-left"></i>
            </div>
            <div className="control-next" data-bs-target="#myCarousel" data-bs-slide="next">
                <i className="bi bi-chevron-right"></i>
            </div>
        </div>
    </div>

}