import React, { useState, useEffect } from 'react';

import './style.scss';

const Toast = (props) => {
    
    const [visible, setVisible] = useState(props.visible)

    useEffect(() => {
        setTimeout(() => {
            setVisible(props.visible)
        }, 0)
    }, [props.visible])

    const handleCloseToast = () => {
        setVisible(false)
    }

    return (
        <>
            { visible && <div className="app-toast-container">
                    <div className="toast-message d-flex align-items-center">
                        <span className="title-toast">{props.message}</span>
                        <div className="close-toast">
                            <span className="material-icons close" onClick={handleCloseToast}>close</span>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

export default Toast;
